import { useState, useEffect } from 'react';

const usePageLoading = () => {
  const [loading, setLoaded] = useState(true);

  // It will be executed before rendering
  useEffect(() => {
    setLoaded(false);
  }, []);
  // [] means like componentDidMount

  return loading;
};

export default usePageLoading;
