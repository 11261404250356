import React from 'react';
import usePageLoading from '../hooks/pageLoading';
import LoadingLayout from './LoadingLayout';
import Missing from '../pages/404';

const MissingRoute = () => {
  const loading = usePageLoading();
  return (
    <>
      {loading ? (
        <span>
          <LoadingLayout showFooter showMenu />
        </span>
      ) : (
        <Missing />
      )}
    </>
  );
};

export default MissingRoute;
