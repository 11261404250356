import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { useFormikContext } from 'formik';
import { Events, scroller } from 'react-scroll';
import Flex from '../../atoms/Flex';
import Box from '../../atoms/Box';
import Text from '../../atoms/Text';

const RateNum = styled.label`
  display: block;
  width: 44px;
  height: 44px;
  line-height: 40px;
  background: ${themeGet('colors.white')};
  border-radius: 50%;
  border: 1px solid ${themeGet('colors.grayscale.300')};
  transition: 0.1s ease;
  text-align: center;
  color: ${themeGet('colors.brand.blue')};
  font-family: ${themeGet('fonts.headings')};
  font-size: 28px;
  cursor: pointer;
`;
const Wrap = styled(Box)`
  &.activeItem label {
    background: ${themeGet('colors.brand.blue')};
    border-color: ${themeGet('colors.brand.blue')};
    color: ${themeGet('colors.white')};
  }
`;

const RatingType = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Using https://github.com/jaredpalmer/formik/issues/146#issuecomment-474775723
const useFocusOnError = ({ fieldRef, name, modal }) => {
  const formikContext = useFormikContext();
  const prevSubmitCountRef = React.useRef(formikContext.submitCount);
  const firstErrorKey = Object.keys(formikContext.errors)[0];
  React.useEffect(() => {
    if ((prevSubmitCountRef.current !== formikContext.submitCount) && !formikContext.isValid) {
      if (fieldRef.current && firstErrorKey === name) {
        scroller.scrollTo(name, {
          duration: 1500,
          smooth: true,
          containerId: modal ? 'ReactModal' : null, // target the modal window. Not the window
          offset: 50, // Scrolls to element + 50 pixels down the page
        });
      }
    }
    prevSubmitCountRef.current = formikContext.submitCount;
  }, [formikContext.submitCount, formikContext.isValid, firstErrorKey]);
};

const RateFood = ({
  name, options, maxWidth, selected, onChange, onBlur, error, touched, modal,
}) => {
  const fieldRef = React.useRef();
  useFocusOnError({ fieldRef, name, modal });
  return (
    <>
      <Flex
        maxWidth={maxWidth}
        justifyContent="space-between"
        mb="20px"
        id="food"
      >
        <RatingType>Poor</RatingType>
        {options.map((opt) => {
          const id = `${name}-${opt.inputValue}`;
          const value = opt.inputValue;
          return (
            <Wrap key={id} className={value <= selected && 'activeItem'}>
              <input
                ref={fieldRef}
                name={name}
                style={{ display: 'none' }}
                id={id}
                type="radio"
                value={value}
                checked={selected === value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <RateNum htmlFor={id}>{opt.label}</RateNum>
            </Wrap>
          );
        })}
        <RatingType>Excellent</RatingType>
      </Flex>
      {touched && error && <Text color="error" fontSize="xs" mb="15px">{error}</Text>}
    </>
  );
};

RateFood.propTypes = {
  name: PropTypes.string,
  modal: PropTypes.bool,
  options: PropTypes.array,
  maxWidth: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  touched: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
RateFood.defaultProps = {
  name: 'rate',
  error: false,
  touched: false,
  modal: false,
  options: [],
  maxWidth: '400px',
  selected: '3',
  onChange: () => {},
  onBlur: () => {},
};

export default RateFood;
