import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';
import { Field, withFormik } from 'formik';
import * as Yup from 'yup';
import { createReview } from '@firsttable/graphql-queries/src';
import FormField from '@firsttable/web-components/molecules/FormField/FormField';
import RateFood from '@firsttable/web-components/molecules/RateFood';
import { BodyTitle, Text } from '@firsttable/web-components/atoms';
import FormLayout from '@firsttable/web-components/organisms/Forms/Form';
import { errorMessage } from '@firsttable/functions';

const ReviewForm = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  modal,
  ...props
}) => (
  <FormLayout {...props} actionLabel="Submit review">
    <BodyTitle size="s">Rate the food</BodyTitle>
    <RateFood
      id="food"
      name="food"
      selected={values.food}
      error={errors.food}
      touched={touched.food}
      onChange={handleChange}
      onBlur={handleChange}
      options={[
        { inputValue: '1', label: '1' },
        { inputValue: '2', label: '2' },
        { inputValue: '3', label: '3' },
        { inputValue: '4', label: '4' },
        { inputValue: '5', label: '5' },
      ]}
      modal={modal}
    />
    <BodyTitle size="s">Rate the service</BodyTitle>
    <RateFood
      id="service"
      name="service"
      selected={values.service}
      error={errors.service}
      touched={touched.service}
      onChange={handleChange}
      onBlur={handleBlur}
      options={[
        { inputValue: '1', label: '1' },
        { inputValue: '2', label: '2' },
        { inputValue: '3', label: '3' },
        { inputValue: '4', label: '4' },
        { inputValue: '5', label: '5' },
      ]}
      modal={modal}
    />
    <BodyTitle size="s" mt="20px">
      Describe your experience
    </BodyTitle>
    <Text mb="3px" fontWeight="bold">
      Write a public review
    </Text>
    <Text mb="3px">
      Share your experience with our community to help food lovers make good
      dining decisions. Your full name will be published with your review.
    </Text>
    <Field
      component={FormField}
      type="textarea"
      name="publicMessage"
      id="PublicMessage"
      placeholder="Your feedback here"
    />
    <Text mb="3px" mt="20px" fontWeight="bold">
      Write a private review
    </Text>
    <Text mb="3px">
      As part of our review process, you also have the option to leave private
      feedback. This feedback is sent directly to the restaurant.
    </Text>
    <Field
      component={FormField}
      type="textarea"
      name="privateMessage"
      id="PrivateMessage"
      placeholder="Your feedback here"
    />
  </FormLayout>
);

ReviewForm.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  modal: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

ReviewForm.defaultProps = {
  modal: false,
};

export default compose(
  graphql(createReview, { name: 'createReviewQuery' }),
  withFormik({
    mapPropsToValues: () => ({
      food: '',
      service: '',
      type: '1',
      publicMessage: '',
      privateMessage: '',
    }),
    validationSchema: () =>
      Yup.object().shape(
        {
          food: Yup.string().required('Required'),
          service: Yup.string().required('Required'),
          publicMessage: Yup.string().when(['privateMessage'], {
            is: (privateMessage) => !privateMessage,
            then: Yup.string().required(
              'Please enter your feedback for private, public or both',
            ),
          }),
          privateMessage: Yup.string().when(['publicMessage'], {
            is: (publicMessage) => !publicMessage,
            then: Yup.string().required(
              'Please enter your feedback for private, public or both',
            ),
          }),
        },
        [['publicMessage', 'privateMessage']],
      ),
    handleSubmit: async (
      values,
      {
        setSubmitting,
        setStatus,
        resetForm,
        props: { createReviewQuery, booking, refetch, hideModal, modal },
      },
    ) => {
      const formData = values;
      const { privateMessage, publicMessage } = formData;
      const reviews = [];
      setSubmitting(true);
      refetch();
      if (publicMessage) {
        reviews.push({
          type: 'Public',
          message: publicMessage,
          food: Number(formData.food),
          service: Number(formData.service),
        });
      }

      if (privateMessage) {
        reviews.push({
          type: 'Private',
          message: privateMessage,
          food: Number(formData.food),
          service: Number(formData.service),
        });
      }

      try {
        await createReviewQuery({
          variables: {
            bookingId: Number(booking.id),
            reviews,
          },
        });

        await refetch();

        if (modal) {
          setTimeout(() => hideModal(), 1000);
        }
        return setSubmitting(false);
      } catch (e) {
        resetForm();
        setStatus({ message: errorMessage(e), type: 'danger' });
        return setSubmitting(false);
      }
    },
  }),
)(ReviewForm);
