import React from 'react';
import { Router } from '@reach/router';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Text, Title } from '@firsttable/web-components/atoms';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA';
import ReviewSummary from '@firsttable/web-components/organisms/ReviewSummary/ReviewSummary';
import ReviewForm from '../components/organisms/Forms/ReviewForm';
import TwoColumnLayout from '../layouts/TwoColumnLayout';
import LoadingLayout from '../layouts/LoadingLayout';
import MissingRoute from '../layouts/MissingRoute';
import NavLink from '../components/atoms/NavLink';
import NavButton from '../components/atoms/NavButton';

const BOOKING_FOR_REVIEW = gql`
  query getLiveBookingForBookingController($key: String) {
    booking: Booking(key: $key) {
      id
      date
      time
      people
      price
      specialConditions
      hasReview
      restaurant {
        id
        title
      }
    }
  }
`;

const BookingControllerLayout = ({ title, col1, col2 }) => (
  <TwoColumnLayout title={title} col1={col1} col2={col2} />
);

BookingControllerLayout.propTypes = {
  title: PropTypes.string,
  col1: PropTypes.node,
  col2: PropTypes.node,
};

const BookingControllerContent = ({ bookingKey }) => {
  const { loading, error, data } = useQuery(BOOKING_FOR_REVIEW, {
    ssr: false,
    variables: { key: bookingKey },
    fetchPolicy: 'network-only',
  });
  if (loading) return <LoadingLayout showFooter showMenu />;
  if (error) {
    return (
      <BookingControllerLayout
        col1={
          <Text>You&rsquo;ve come to the wrong place to write a review.</Text>
        }
      />
    );
  }
  if (data.booking.hasReview) {
    return (
      <BookingControllerLayout
        col1={
          <>
            <Title>Review Complete</Title>
            <Text>This booking has already been reviewed.</Text>
          </>
        }
        col2={<ContactCTA NavButtonComponent={NavButton} />}
      />
    );
  }
  return (
    <TwoColumnLayout
      title={`Review ${data.booking.restaurant.title}`}
      col1={<ReviewForm booking={data.booking} />}
      col2={<ReviewSummary booking={data.booking} NavLinkComponent={NavLink} />}
    />
  );
};

BookingControllerContent.propTypes = {
  bookingKey: PropTypes.string,
};
BookingControllerContent.defaultProps = {
  bookingKey: '',
};

const BookingController = () => (
  <Router>
    <BookingControllerContent path="/BookingController/:bookingRef/review/:bookingKey" />
    <BookingControllerContent path="/BookingController/" />
    <MissingRoute default />
  </Router>
);

BookingController.propTypes = {};
BookingController.defaultProps = {};

export default BookingController;
