import React from 'react';
import PropType from 'prop-types';
import styled from 'styled-components';
import media from 'styled-media-query';
import { themeGet } from '@styled-system/theme-get';
import { breakpointsObject as bp } from '@firsttable/web-theme';
import { Title, Text, Box, Link, List } from '../../atoms';

const StyledInfoBox = styled(Box)`
  padding: 58px 64px;
  ${media.lessThan(bp.s)`
    border-top: 1px ${themeGet('colors.grayscale.300')} solid;
    border-bottom: 1px ${themeGet('colors.grayscale.300')} solid;
    margin: 20px 0;
    padding: 20px 0;
    background: none;
  `};
`;

const ReviewSummary = ({ NavLinkComponent }) => (
  <StyledInfoBox colors="gradient.blue">
    <Title size="h4">Review Guidelines</Title>
    <Text>
      Your review must follow{' '}
      <Link NavComponent={NavLinkComponent} to="/review-guidelines/" underline>
        First Table's Review Guidelines
      </Link>
      . A good reviewer is as unbiased as possible, offers constructive feedback
      and summarises the overall dining experience (from booking to food to
      atmosphere and service). <br />
      <br />
      Please note, public reviews can take up to 30 days to be displayed on our
      website.
    </Text>
  </StyledInfoBox>
);

ReviewSummary.propTypes = {
  NavLinkComponent: PropType.any.isRequired,
};
ReviewSummary.defaultProps = {};

export default ReviewSummary;
